import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.states';
import { Globals } from 'src/app/common/globals';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { Geolocation } from 'src/app/common/model/geolocation';
import { calculateDistance } from 'src/app/common/functions';
import { attendanceSaveAction } from 'src/app/api/user/user.actions';
import baselineAccessAlarms from '@iconify/icons-ic/baseline-access-alarms';
import twotoneEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MyStoreDetailComponent } from '../../stores/my-store-detail/my-store-detail.component';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import { ActivatedRoute } from '@angular/router';

// declare var SMap: any;
// declare var JAK: any;

@Component({
    selector   : 'locations-map-component',
    templateUrl: './locations-map.component.html',
    styleUrls  : ['./locations-map.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LocationsMapComponent implements AfterViewInit, OnDestroy
{
    @Input()
    showOnlyUserRelated = false;

    @Input()
    highlightedLocationIDs: number[] = null;

    @Input()
    centerToPosition: Geolocation.GeolocationCoordinates = null;

    @ViewChild('map', { static: true }) mapElem: ElementRef<HTMLElement>;
    //@ViewChild('map') mapElem: ElementRef<HTMLElement>;

    baselineAccessAlarms = baselineAccessAlarms;
    twotoneEditLocation = twotoneEditLocation;

    attendanceTypeEnum = Honeycomb.Common.Enums.AttendanceType;
    attendanceType: Honeycomb.Common.Enums.AttendanceType;
    locationID: number = null;

    geolocationAvailable = false;

    subscriptions: Array<Subscription> = [];

    address: string;

    closestLocation: any; // Honeycomb.Tenant.LookupTables.IService.Model.LocationShort;
    distanceToClosestLocation: Observable<number> = of(null);
    selectedLocation: any; // Honeycomb.Tenant.LookupTables.IService.Model.LocationShort;
    distanceToSelectedLocation: Observable<number>  = of(null);

    smap: any;
    myLayer: any;

    disableButtons: Observable<boolean> = of(true);

    // tslint:disable-next-line: new-parens
    time: Observable<Date> = of(new Date);

    constructor(
        private globals: Globals,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private trans: TranslateService,
        private route: ActivatedRoute,
        private store: Store<AppState>,
        @Inject('LookupLocationController') private lookupLocationController:  Honeycomb.Tenant.LookupTables.IService.LocationsController,
        // tslint:disable-next-line:max-line-length
        @Inject('TaskerLocationController') private taskerLocationController:  Honeycomb.Tenant.Tasker.IService.Controller.LocationController
    )
    {
    }

    async ngAfterViewInit(): Promise<void> {
        this.geolocationAvailable = this.globals.geoLocationAvailable;
        await this.refreshLocation();
    }

    public async refreshLocation() {
        const lastPosition = this.globals.lastPosition;
        if (!lastPosition) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                this.globals.lastPosition = position;
                await this.setupMap(position);
            });
        } else {
            await this.setupMap(lastPosition);
        }
    }

    private async setupMap(lastPosition: Geolocation.GeolocationPosition) 
    {
        if (this.mapElem /* && !this.smap */) {
            const mapCenter: [number, number] = this.centerToPosition
                ? [this.centerToPosition.latitude, this.centerToPosition.longitude]
                : [lastPosition.coords.latitude, lastPosition.coords.longitude];
    
            if (this.smap) { 
                this.smap.remove();
                //this.smap.setView(mapCenter, 14);
                //this.smap = L.map(this.mapElem.nativeElement).setView(mapCenter, 14);
                //return;
            } 
            
            {
                // Initialize the map
                this.smap = L.map(this.mapElem.nativeElement).setView(mapCenter, 14);

                // Add the Mapy.cz tile layer
                const API_KEY = '24RYE91Wi7Rb47D7lru0_FcRNURLqRbtjFli_94FnY8';
                L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${API_KEY}`, {
                    minZoom: 0,
                    maxZoom: 19,
                    attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
                }).addTo(this.smap);

                // Add a custom logo control
                const LogoControl = L.Control.extend({
                    options: {
                        position: 'bottomleft',
                    },
                    onAdd: function () {
                        const container = L.DomUtil.create('div');
                        const link = L.DomUtil.create('a', '', container);

                        link.setAttribute('href', 'http://mapy.cz/');
                        link.setAttribute('target', '_blank');
                        link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
                        L.DomEvent.disableClickPropagation(link);

                        return container;
                    },
                });

                // Add the logo control to the map
                new LogoControl().addTo(this.smap);
            }

            // Fetch locations
            let locationToSearch = null;
            if (this.showOnlyUserRelated) {
                locationToSearch = await lastValueFrom(this.taskerLocationController
                    .UserRelatedLocations());
            }
    
            const foundLocation = await lastValueFrom(this.lookupLocationController
                .ListSimple(
                    null,
                    null,
                    300,
                    true,
                    null,
                    null,
                    lastPosition.coords.latitude,
                    lastPosition.coords.longitude,
                    locationToSearch != null ? JSON.stringify(locationToSearch) : null
                ));
    
            // Classify locations into highlighted and normal
            const highlightedLocations: any[] = [];
            const normalLocations: any[] = [];
    
            for (const location of foundLocation) {
                if (
                    this.highlightedLocationIDs &&
                    this.highlightedLocationIDs.indexOf(location.locationID) !== -1
                ) {
                    highlightedLocations.push(location);
                } else {
                    normalLocations.push(location);
                }
            }
    
            // Add markers for normal and highlighted locations
            normalLocations.forEach((loc) =>
                this.addMapPosition(
                    loc.longitude,
                    loc.latitude,
                    '/assets/drop-dt.png',
                    loc.locationID.toString(),
                    loc
                )
            );
    
            highlightedLocations.forEach((loc) =>
                this.addMapPosition(
                    loc.longitude,
                    loc.latitude,
                    '/assets/drop-dt-highlight.png',
                    loc.locationID.toString(),
                    loc
                )
            );
        }
    }
    
    private addMapPosition(longitude: number, latitude: number, iconUrl: string, id: string, location: any
    ): void { const icon = L.icon({ iconUrl, iconSize: [32, 32], iconAnchor: [16, 32] });
    
        const marker = L.marker([latitude, longitude], { icon }).addTo(this.smap);

        var goToStoreLabel = this.trans.instant('tasker.go-store-detail');
        var routePrefix = !!this.showOnlyUserRelated ? 'my-stores' : 'main';

        const popupContent = `
            <strong>${location.name || 'Unknown Location'}</strong><br>
            ${location.street || ''}<br>
            ${location.phone || ''}<br>
            ${location.email || ''}<br>
            <a href="/stores/${routePrefix}/detail/${id}" class="pointer view-details-link" data-location-id="${id}" click="openDetail(${id})">
                ${goToStoreLabel}
            </a>
        `;

        var contentSpan = document.createElement('span') as HTMLSpanElement;
        contentSpan.innerHTML = popupContent;
        contentSpan.attributes['data-location-id'] = id;
    
        marker.bindPopup(contentSpan, {
            offset: [0, -30], // Adjust the offset to move the popup above the marker
            closeButton: true, // Optional: Include a close button
            autoPan: true, // Ensure the map pans to keep the popup visible
        });
    
        marker.on('click', (e: any) => {
            const target = e.target.getElement();
            const link = target.querySelector('.view-details-link');
            if (link) {
                e.preventDefault();
                const locationID = link.getAttribute('data-location-id');
                if (locationID) {
                    this.openDetail(Number(locationID));
                }
            }
        });
    }

    openDetail(locationID: number) {
        this.dialog.open(MyStoreDetailComponent, {
            data: { locationID, permission: 'my-store' }
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
