<h2 mat-dialog-title *ngIf="attendanceType === attendanceTypeEnum.notSet">{{ 'tasker.attendance-dialog.title.enter' | translate }}</h2>
<h2 mat-dialog-title *ngIf="attendanceType === attendanceTypeEnum.enter">{{ 'tasker.attendance-dialog.title.exit' | translate }}</h2>
<mat-dialog-content>
    <form (ngSubmit)="save()" [formGroup]="form" style="min-width: 70vw;">
        <div fxFlex fxLayout="column">
            <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">

                <locations-map-component #locationsMap [showOnlyUserRelated]="false"
                    [highlightedLocationIDs]="highlightedLocationIDs | async" [centerToPosition]="centerToPosition | async" fxFlex>
                </locations-map-component>

                <div fxFlex fxLayout="column" fxFlexAlign="start">
                    <div *ngIf="!closestLocation && !positionErrorCode">
                        <b>{{ 'tasker.attendance-dialog.acquiring-position' | translate }}</b>
                    </div>
                    <div *ngIf="!!positionErrorCode">
                        <b style="color: var(--btn-red)">{{ 'tasker.attendance-dialog.position-error.' + getPositionErrorName(positionErrorCode) | translate }}</b>
                    </div>
                    <mat-form-field fxFlex class="mt-3">
                        <mat-label>
                            <ng-container *ngIf="attendanceType === attendanceTypeEnum.notSet">{{ 'tasker.attendance-dialog.enter-time' | translate }}</ng-container>
                            <ng-container *ngIf="attendanceType === attendanceTypeEnum.enter">{{ 'tasker.attendance-dialog.exit-time' | translate }}</ng-container>
                        </mat-label>
                        <input matInput [readonly]="true" [value]="time | async | date : 'HH:mm'">
                        <mat-icon [icIcon]="baselineAccessAlarms" class="mr-3" matPrefix></mat-icon>
                    </mat-form-field>
                    <div fxFlex fxLayout="row" *ngIf="!!closestLocation && attendanceType === attendanceTypeEnum.notSet">
                        <mat-form-field fxFlex  class="mt-3">
                            <mat-label>
                                {{ 'tasker.attendance-dialog.closest-location' | translate }}
                            </mat-label>
                            <input matInput [readonly]="true" value="{{ closestLocation.name }}, {{ closestLocation.street }} {{ closestLocation.distance.toFixed(0) }}m">
                            <mat-icon [icIcon]="baselineAccessAlarms" class="mr-3" matPrefix></mat-icon>
                        </mat-form-field>
                        <button mat-button style="height: 44px; margin-top: 12px;" type="button" (click)="refreshLocation()">{{ 'tasker.attendance-dialog.refresh' | translate }}</button>
                    </div>

                    <div fxFlex fxLayout="row"  *ngIf="!!selectedLocation && attendanceType === attendanceTypeEnum.enter">
                        <mat-form-field fxFlex class="mt-3">
                            <mat-label>
                                {{ 'tasker.attendance-dialog.selected-location' | translate }}
                            </mat-label>
                            <input matInput [readonly]="true" value="{{ selectedLocation.name }}, {{ selectedLocation.street }}">
                            <mat-icon [icIcon]="twotoneEditLocation" class="mr-3" matPrefix></mat-icon>
                        </mat-form-field>
                        
                        <button mat-button type="button" class="button" style="height: 44px; margin-top: 12px;" (click)="refreshLocation()">{{ 'tasker.attendance-dialog.refresh' | translate }}</button>
                    </div>
                    <div fxFlex>
                        <mat-error *ngIf="attendanceType === attendanceTypeEnum.notSet && !!closestLocation && closestLocation.distance > 500">
                            {{ 'tasker.attendance-dialog.out-of-distance' | translate }}    
                        </mat-error>
                    </div>
                    <div fxFlex>
                        <mat-error *ngIf="!!selectedLocation && ((distanceToSelectedLocation | async) > 500 || (distanceToSelectedLocation | async) === 500)">
                            {{ 'tasker.attendance-dialog.out-of-distance-exit' | translate }}    
                        </mat-error>
                    </div>
                    
                    <!-- <div *ngIf="attendanceType === attendanceTypeEnum.notSet">
                        {{ 'tasker.attendance-dialog.enter-time' | translate }}  {{ time | async }}
                    </div>
                    <div *ngIf="attendanceType === attendanceTypeEnum.enter">
                        {{ 'tasker.attendance-dialog.exit-time' | translate }}  {{ time | async }}
                    </div>
                    <div *ngIf="!!closestLocation">
                        {{ 'tasker.attendance-dialog.closest-location' | translate }} {{ closestLocation.name }}, {{ closestLocation.street }} {{ closestLocation.distance.toFixed(0) }}m
                    </div> -->
                    <!-- <div *ngIf="!!closestLocation && closestLocation.distance > 500" class="warning" color="warning">
                        {{ 'tasker.attendance-dialog.out-of-distance' | translate }}
                    </div> -->
                    
                </div>
            </div>
        </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'tasker.attendance-dialog.cancel' | translate }}</button>
  <button mat-button (click)="setEnter()" [disabled]="disableButtons | async" color="primary" *ngIf="attendanceType === attendanceTypeEnum.notSet && !!closestLocation && closestLocation.distance < 500">{{ 'tasker.attendance-dialog.submit-enter' | translate }}</button>
  <button mat-button (click)="setExit()"  [disabled]="disableButtons | async" color="primary" *ngIf="attendanceType === attendanceTypeEnum.enter && !positionErrorCode && selectedLocation && (distanceToSelectedLocation | async) < 500" cdkFocusInitial>{{ 'tasker.attendance-dialog.submit-exit' | translate }}</button>
  <button mat-button (click)="setExit()"  color="warn" *ngIf="attendanceType === attendanceTypeEnum.enter && !positionErrorCode && selectedLocation && ((distanceToSelectedLocation | async) > 500 || (distanceToSelectedLocation | async) === 500)" cdkFocusInitial class="warning">{{ 'tasker.attendance-dialog.submit-exit-override' | translate }}</button>
</mat-dialog-actions>